<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                    <b-col cols="12">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">Add new event</h2>
                            <div class="profile__content">
                                <p v-if="addEventForm.localError" class="custom-error mb-3">{{ addEventForm.localError }}</p>
                                <p v-if="getAdminEventsError" class="custom-error mb-3"> {{ getAdminEventsError }} </p>
                                <form :class="getDisabledAdminEvent ? 'disabled-element' : ''" @submit.prevent="addAdminEvent" autocomplete="off">
                                    <b-row>
                                        <b-col cols="12">
                                            <span class="profile__label float-right">*Required fields</span>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Event name*</label>
                                                <input :disabled="getDisabledAdminEvent" v-model.trim="addEventForm.eventName" @input="$v.addEventForm.eventName.$touch()" :class="[{'input-error' : $v.addEventForm.eventName.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type event name" autocomplete="off" />
                                                <div v-if="$v.addEventForm.eventName.$error">
                                                    <p v-if="!$v.addEventForm.eventName.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addEventForm.eventName.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">E-mail address</label>
                                                <input :disabled="getDisabledAdminEvent"  @input="$v.addEventForm.eventContactEmail.$touch()" :class="[{'input-error' : $v.addEventForm.eventContactEmail.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model.trim="addEventForm.eventContactEmail" class="form-input mb-1" placeholder="Type contact e-mail" type="email" autocomplete="off" />
                                                <div v-if="$v.addEventForm.eventContactEmail.$error">
                                                    <p v-if="!$v.addEventForm.eventContactEmail.email" class="custom-error">E-mail address is invalid</p>
                                                    <p v-else-if="!$v.addEventForm.eventContactEmail.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Event website URL<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />With http or https protocol</span></label>
                                                <input :disabled="getDisabledAdminEvent"  @input="$v.addEventForm.eventContactUrl.$touch()" :class="[{'input-error' : $v.addEventForm.eventContactUrl.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model.trim="addEventForm.eventContactUrl" class="form-input mb-1" placeholder="Type event website URL" autocomplete="off" />
                                                <div v-if="$v.addEventForm.eventContactUrl.$error">
                                                    <p v-if="!$v.addEventForm.eventContactUrl.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                    <p v-else-if="!$v.addEventForm.eventContactUrl.urlCustomValidator" class="custom-error">This field must start with either http:// or https://</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">City*</label>
                                                <input :disabled="getDisabledAdminEvent"  @input="$v.addEventForm.eventCity.$touch()" :class="[{'input-error' : $v.addEventForm.eventCity.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model.trim="addEventForm.eventCity" class="form-input mb-1" placeholder="Type city" autocomplete="off" />
                                                <div v-if="$v.addEventForm.eventCity.$error">
                                                    <p v-if="!$v.addEventForm.eventCity.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addEventForm.eventCity.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Event date*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />day, month, year</span></label>
                                                <date-picker :disabled="getDisabledAdminEvent" :class="[{'input-error' : $v.addEventForm.eventDate.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model.trim="addEventForm.eventDate" class="mb-1"  lang="en" format="DD-MM-YYYY" placeholder="Select event date"></date-picker>
                                                <div v-if="$v.addEventForm.eventDate.$error">
                                                    <p v-if="!$v.addEventForm.eventDate.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addEventForm.eventDate.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Event type*</label>
                                                <v-select :disabled="getDisabledAdminEvent" @input="$v.addEventForm.eventType.$touch()" :class="[{'input-error' : $v.addEventForm.eventType.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model.trim="addEventForm.eventType" class="custom-sorter mb-1" labelTitle = "Select event type" :options="eventTypes" />
                                                <div v-if="$v.addEventForm.eventType.$error">
                                                    <p v-if="!$v.addEventForm.eventType.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addEventForm.eventType.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Phone number</label>
                                                <input :disabled="getDisabledAdminEvent" @input="$v.addEventForm.eventContactPhone.$touch()" :class="[{'input-error' : $v.addEventForm.eventContactPhone.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model.trim="addEventForm.eventContactPhone" class="form-input mb-1" placeholder="Type contact phone number" autocomplete="off" />
                                                <div v-if="$v.addEventForm.eventContactPhone.$error"> <p v-if="!$v.addEventForm.eventContactPhone.maxLength" class="custom-error">This field must not exceed 20 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Place address*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Street name and street number</span></label>
                                                <input :disabled="getDisabledAdminEvent" @input="$v.addEventForm.eventAddress.$touch()" :class="[{'input-error' : $v.addEventForm.eventAddress.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model.trim="addEventForm.eventAddress" class="form-input mb-1" placeholder="Type place address" autocomplete="off" />
                                                <div v-if="$v.addEventForm.eventAddress.$error">
                                                    <p v-if="!$v.addEventForm.eventAddress.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addEventForm.eventAddress.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Country*</label>
                                                <v-select :disabled="getDisabledAdminEvent" @input="$v.addEventForm.eventCountry.$touch()" :class="[{'input-error' : $v.addEventForm.eventCountry.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model.trim="addEventForm.eventCountry" class="custom-sorter mb-1" labelTitle = "Select country" :options="countries" :searchable = true />
                                                <div v-if="$v.addEventForm.eventCountry.$error">
                                                    <p v-if="!$v.addEventForm.eventCountry.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addEventForm.eventCountry.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Event description*</label>
                                                <vue-editor :disabled="getDisabledAdminEvent"  @input="$v.addEventForm.eventDescription.$touch()" :class="[{'input-error' : $v.addEventForm.eventDescription.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model.trim="addEventForm.eventDescription" :editorToolbar="customToolbar"></vue-editor>
                                                <div v-if="$v.addEventForm.eventDescription.$error">
                                                    <p v-if="!$v.addEventForm.eventDescription.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addEventForm.eventDescription.maxLength" class="custom-error">This field must not exceed 3000 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Location* <span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Move the blue marker on the map and specify the location</span></label>
                                                <div class="profile__map">
                                                    <l-map :zoom="zoom" :center="center" :options="{zoomControl: false}">
                                                        <l-tile-layer
                                                        :url="url"
                                                        layer-type="base"></l-tile-layer>
                                                        <l-marker :lat-lng="addEventForm.eventMarker" :draggable="true" @update:latLng="positionUpdate"></l-marker>
                                                        <l-control-zoom position="topright"></l-control-zoom>
                                                    </l-map>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Latitude</label>
                                                <input readonly :disabled="getDisabledAdminEvent" @input="$v.addEventForm.eventMarker.lat.$touch()" :class="[{'input-error' : $v.addEventForm.eventMarker.lat.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model="addEventForm.eventMarker.lat" class="form-input mb-1" />
                                                <div v-if="$v.addEventForm.eventMarker.lat.$error">
                                                    <p v-if="!$v.addEventForm.eventMarker.lat.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Longitude</label>
                                                <input readonly :disabled="getDisabledAdminEvent" @input="$v.addEventForm.eventMarker.lng.$touch()" :class="[{'input-error' : $v.addEventForm.eventMarker.lng.$error }, getDisabledAdminEvent ? 'disabled-element' : '' ]" v-model="addEventForm.eventMarker.lng" class="form-input mb-1" />
                                                <div v-if="$v.addEventForm.eventMarker.lng.$error">
                                                    <p v-if="!$v.addEventForm.eventMarker.lng.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Event banner*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Recommended size: 700px by 430px, JPG, PNG, max 5MB</span></label>
                                                <b-row>
                                                    <b-col cols="12">
                                                        <div v-if="addEventForm.selectedFile" id="eventImage" class="event-details__image"></div>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <div class="custom-upload mt-3">
                                                            <label for="fileUpload" class="custom-upload-label">
                                                                <simple-line-icons icon="picture" size="small" color="#504e70" />Choose file
                                                            </label>
                                                            <input :class="getDisabledAdminEvent ? 'disabled-element' : ''" @change="onFileChanged" id="fileUpload" type="file"/>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                                <div v-if="$v.addEventForm.selectedFile.$error">
                                                    <p v-if="!$v.addEventForm.selectedFile.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <button :disabled="getDisabledAdminEvent" type="submit" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Save event</button>
                                        </b-col>
                                    </b-row>
                                </form>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import VSelect from '../assets/files/customSelect.esm.js'
import DatePicker from 'vue2-datepicker'
import { VueEditor, Quill } from 'vue2-editor'
import countriesList from '../assets/files/countriesList.js'
import eventTypesList from '../assets/files/eventTypesList.js'
import { mapGetters } from 'vuex'
import {
  email,
  required,
  maxLength
} from 'vuelidate/lib/validators'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
  LControlZoom
} from 'vue2-leaflet'

function urlCustomValidator (value) {
  if (value !== '') {
    var pattern = /^((http|https):\/\/)/

    if (!pattern.test(value)) {
      return false
    }
  }
  return true
}

export default {
  components: {
    SimpleLineIcons,
    VSelect,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LControlZoom,
    DatePicker,
    VueEditor
  },
  data: function () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 12,
      center: [40.73461490255838, -73.98722073063256],
      customToolbar: [
        ['bold', 'italic', 'underline', 'link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
      ],
      eventTypes: [],
      countries: [],
      addEventForm: {
        eventName: null,
        eventType: null,
        eventContactEmail: '',
        eventContactPhone: '',
        eventContactUrl: '',
        eventAddress: null,
        eventCity: null,
        eventCountry: null,
        eventDate: null,
        eventDescription: null,
        eventMarker: {
          lat: 40.73461490255838, lng: -73.98722073063256
        },
        selectedFile: null,
        localError: null
      }
    }
  },
  validations: {
    addEventForm: {
      eventName: {
        required: required,
        maxLength: maxLength(200)
      },
      eventType: {
        required: required,
        maxLength: maxLength(200)
      },
      eventContactEmail: {
        email: email,
        maxLength: maxLength(200)
      },
      eventContactPhone: {
        maxLength: maxLength(20)
      },
      eventContactUrl: {
        maxLength: maxLength(200),
        urlCustomValidator
      },
      eventAddress: {
        required: required,
        maxLength: maxLength(200)
      },
      eventCity: {
        required: required,
        maxLength: maxLength(200)
      },
      eventCountry: {
        required: required,
        maxLength: maxLength(200)
      },
      eventDate: {
        required: required,
        maxLength: maxLength(200)
      },
      eventDescription: {
        required: required,
        maxLength: maxLength(3000)
      },
      eventMarker: {
        lat: {
          required: required
        },
        lng: {
          required: required
        }
      },
      selectedFile: {
        required: required
      }
    }
  },
  methods: {
    positionUpdate (latLng) {
      this.addEventForm.eventMarker = latLng
    },
    addAdminEvent () {
      this.$store.dispatch('setDisabledAdminEvent')
      this.$v.addEventForm.$touch()
      if (!this.$v.addEventForm.$invalid) {
        this.$store.dispatch('addAdminEvent', { name: this.addEventForm.eventName, type: this.addEventForm.eventType, email: this.addEventForm.eventContactEmail, phone: this.addEventForm.eventContactPhone, website: this.addEventForm.eventContactUrl, address: this.addEventForm.eventAddress, city: this.addEventForm.eventCity, country: this.addEventForm.eventCountry, date: this.addEventForm.eventDate, desc: this.addEventForm.eventDescription, lat: this.addEventForm.eventMarker.lat, lng: this.addEventForm.eventMarker.lng, image: this.addEventForm.selectedFile, imageName: this.getGuid() })
          .then(() => {
            this.scrollToTop(500)
          })
          .catch(error => {
            this.addEventForm.localError = error.message
            this.$store.dispatch('clearDisabledAdminEvent')
          })
      } else {
        this.scrollToTop(500)
        this.addEventForm.localError = 'Invalid form'
        this.$store.dispatch('clearDisabledAdminEvent')
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    },
    onFileChanged (event) {
      if (event.target.files[0] != undefined) {
        this.addEventForm.selectedFile = event.target.files[0]

        var inputFile = document.querySelector('#fileUpload')

        var selectedValue = document.querySelector('#fileUpload').value

        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

        var fileSize = this.addEventForm.selectedFile.size / 1024 / 1024 // in MB

        if (!allowedExtensions.exec(selectedValue)) {
          this.$swal({
            type: 'error',
            title: 'Invalid file extension',
            text: 'Supported file formats: .jpeg .jpg .png .gif',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.addEventForm.selectedFile = null

          return false
        } else if (fileSize > 5) {
          this.$swal({
            type: 'error',
            title: 'File size is too large',
            text: 'Uploaded file size must not exceed 5MB',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.addEventForm.selectedFile = null

          return false
        } else {
          // Image preview
          if (this.addEventForm.selectedFile) {
            var reader = new FileReader()
            reader.onload = function (e) {
              document.getElementById('eventImage').style.backgroundImage = 'url("' + e.target.result + '")'
            }
            reader.readAsDataURL(inputFile.files[0])
          }
        }
      }
    },
    getGuid () {
      function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      return (
        s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
      )
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledAdminEvent')
  },
  created () {
    this.countries = countriesList
    this.eventTypes = eventTypesList

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = [position.coords.latitude, position.coords.longitude]
        this.addEventForm.eventMarker.lat = position.coords.latitude
        this.addEventForm.eventMarker.lng = position.coords.longitude
      })
    }
  },
  computed: {
    ...mapGetters([
      'getAdminEventsError',
      'getDisabledAdminEvent'
    ])
  }
}
</script>
